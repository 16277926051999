<template>
  <ResourceView>
    <VHeader>
      <template #avatar>
        <VImage
          :name="getText(data.texts, 'name')"
          :color="data.colour"
          :width="12"
          :height="12"
          class="items-center justify-center"
        />
      </template>

      <template #title>
        {{ getText(data.texts, "name") }}
      </template>

      <template #subtitle>
        <div class="version">{{ $t("app.versions") }} {{ data.version }}</div>
      </template>

      <template #status>
        <VChip
          v-if="data.status"
          :text="$t(`app.${data.status}`)"
          :class="getStatusColor(data.status)"
        />
      </template>

      <template #tabs>
        <div class="flex justify-between">
          <VTabs
            :current-tab="currentTab"
            :tabs="tabs.slice(0, -1)"
            @click="onClickRedirect"
          />

          <VTabs
            :current-tab="currentTab"
            :tabs="tabs.slice(-1)"
            @click="onClickRedirect"
          />
        </div>
      </template>
    </VHeader>

    <router-view
      :id="id"
      :resource="data"
      :is-loading="isLoading"
      @click:cancel="onClickCancel"
      @click:save="onClickSave"
      @refresh="getData"
    />
  </ResourceView>
</template>

<script>
import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";
// Composables
import useCollection from "@/composables/useCollection";
import useUpdate from "@/composables/useUpdate";
import useTexts from "@/composables/useTexts";
import useColor from "@/composables/useColor";
import useTeachingLogs from "@/composables/useTeachingLogs";
import useTabs from "@/composables/useTabs";
// Components
import ResourceView from "./ResourceView";
import VHeader from "@/components/VHeader";
import VTabs from "@/components/VTabs";
import VChip from "@/components/VChip";
import VImage from "@/components/VImage";

export default {
  components: {
    ResourceView,
    VHeader,
    VTabs,
    VChip,
    VImage
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },
  setup(props) {
    // Misc
    const router = useRouter();
    const route = useRoute();

    // Composables
    const { buildTab } = useTabs();
    const { isAuthorized } = useTeachingLogs();
    const { getStatusColor } = useColor();
    const { getText } = useTexts();
    const { endpoint, route: baseRoute } = useCollection();
    const { data, onClickCancel, onClickSave, isLoading, getData } = useUpdate({
      endpoint,
      route: baseRoute,
      id: props.id,
      relations: [
        "texts",
        "instructors",
        "competencies",
        "roles",
        "tags",
        "trainings"
      ]
    });

    // Computed
    const currentTab = computed(() => {
      switch (route.name) {
        case `${baseRoute}-overview`:
          return "overview";
        case `${baseRoute}-details`:
          return "details";
        case `${baseRoute}-registrations`:
          return "registrations";
        case `${baseRoute}-feedback`:
          return "feedback";
        case `${baseRoute}-logs`:
          return "logs";
        default:
          return "";
      }
    });

    const tabs = computed(() => {
      return [
        buildTab({ name: "overview" }),
        buildTab({ name: "details" }),
        buildTab({ name: "registrations" }),
        buildTab({ name: "feedback" }),
        buildTab({
          name: "logs",
          disabled: !isAuthorized.value
        })
      ];
    });

    // Methods
    const onClickRedirect = tab => {
      router.push({
        name: `${baseRoute}-${tab}`,
        params: {
          id: props.id
        }
      });
    };

    return {
      onClickRedirect,
      tabs,
      currentTab,
      // useColor
      getStatusColor,
      // useTexts
      getText,
      // useUpdate
      data,
      onClickCancel,
      onClickSave,
      isLoading,
      getData
    };
  }
};
</script>
